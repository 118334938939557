








import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class SlideLogin extends Vue {
  @Prop() disabled: any;
  slideStatus: boolean = false;
  startLeft: number = 0;
  left: number = 0;
  verityStatus: number = 0;

  get style() {
    return `left:${this.left}px`;
  }

  get containerStyle() {
    let color = "#3375ff";
    if (this.verityStatus === 1) {
      color = "green";
    }
    return `background:${color}`;
  }
  clear() {
    this.left = 0;
    this.verityStatus = 0;
    this.startLeft = 0;
    this.slideStatus = false;
  }
  onMouseDown(e: any) {
    this.slideStatus = true;
    this.startLeft = e.clientX;
  }
  onMouseMove(e: any) {
    if (this.slideStatus) {
      let left = e.clientX - this.startLeft;
      if (left > 448 - 80) left = 448 - 80;
      if (left <= 0) left = 0;
      this.left = left;
      if (left === 448 - 80) {
        setTimeout(() => {
          this.verityStatus = 1;
          // this.$emit("success");
        }, 500);
      } else {
        this.verityStatus = 0;
      }
    }
  }
  onMouseUp(e: any) {
    this.slideStatus = false;
    if (this.left < 448 - 80) {
      this.left = 0;
    }
    if (this.left === 448 - 80) {
      setTimeout(() => {
        this.verityStatus = 1;
        this.$emit("success");
      }, 500);
    }
  }

  mounted() {
    const app = document.getElementById("app");
    window.addEventListener("mousemove", this.onMouseMove);
    window.addEventListener("mouseup", this.onMouseUp);
  }
  beforeUnmount() {
    window.removeEventListener("mousemove", this.onMouseMove);
    window.removeEventListener("mouseup", this.onMouseUp);
  }
}
