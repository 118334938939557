

























































































import {Component, Vue, Watch} from "vue-property-decorator";
import {Route} from "vue-router";
import {Dictionary} from "vue-router/types/router";
import {Form as ElForm, Input} from "element-ui";
import {UserModule} from "@/store/modules/user";
import {isValidUsername} from "@/utils/validate";
import {ILoginParams, userApi} from "@/api/user";
import router from "@/router";
// @ts-ignore
import SlideLogin from "@/components/SlideLogin";
import QrCodeWithLogo from "qr-code-with-logo";
import Wx from "@/assets/weixin.png";
import dingding from '../../../public/img/icons/dingding.png'
import {nanoid} from "nanoid";

@Component({components: {SlideLogin}})
export default class extends Vue {
  private loginForm = {} as ILoginParams;
  private loginCodeSendLoading = false;
  private showExpire = false;
  private loginCode = nanoid(32)
  private checkInterval = null
  private dingdingUrl = dingding

  private validateUsername = (rule: any, value: string, callback: Function) => {
    if (!isValidUsername(value)) {
      callback(new Error("请输入正确登录名"));
    } else {
      callback();
    }
  };

  clearDingDingEvent() {
    // console.log(1111)
  }

  ddLoginInit() {
    // 回调url做成配置文件
    // let url = encodeURIComponent('http://127.0.0.1:8080/#/login');
    let url = encodeURIComponent('https://www.zjiims.com/#/login');

    // appid 放入配置文件
    let appid = 'dingjati2ccmzgkmmkv1'
    let goto = encodeURIComponent(`https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${appid}&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=${url}`)
    // @ts-ignore
    let obj = DDLogin({
      id: "login_container",
      goto: goto,
      style: "border:none;background-color:#FFFFFF;",
      width: "230",
      height: "300"
    });
    // @ts-ignore
    let handleMessage = (event) => {
      let origin = event.origin;
      if (origin == "https://login.dingtalk.com") {
        let loginTmpCode = event.data;
        window.location.href = `https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${appid}&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=${url}&loginTmpCode=${loginTmpCode}`
      }
    };
    if (typeof window.addEventListener != 'undefined') {
      window.addEventListener('message', handleMessage, false);
      // @ts-ignore
    } else if (typeof window.attachEvent != 'undefined') {
      // @ts-ignore
      window.attachEvent('onmessage', handleMessage);
    }
  }

  handleCodeLogin(code: any) {
    // TODO 判断用户状态
    userApi.getDIngdingLoginResult(code).then(() => {
      this.$router.push(this.redirect ? this.redirect.toString() : "/dashboard")
    }).catch((e) => {
      this.$message.warning(e)
    });
  }

  createQr(content: string) {
    this.$nextTick(() => {
      // @ts-ignore
      const oldCanvas = document.getElementById("wxLogin").firstChild
      const myCanvas = document.createElement("canvas");
      if (!oldCanvas) {
        // @ts-ignore
        document.getElementById("wxLogin").appendChild(myCanvas);
      } else {
        // @ts-ignore
        document.getElementById("wxLogin").replaceChild(myCanvas, oldCanvas);
      }
      QrCodeWithLogo.toCanvas({
        canvas: myCanvas,
        content: content,
        width: 200,
        logo: {
          src: Wx,
          // @ts-ignore
          radius: 8,
        },
      });
    })
  }

  freshLoginCode() {
    // @ts-ignore
    clearInterval(this.checkInterval)
    this.showExpire = false
    this.loginCode = nanoid(32)
    this.previewQrcode()
  }

  previewQrcode() {
    // @ts-ignore
    if (!document.getElementById("wxLogin").firstChild) {
      this.createQr("https://www.zjiims.com/qr/login?code=hello")
    }
    this.loginCodeSendLoading = true
    const url = `https://www.zjiims.com/qr/login?code=${this.loginCode}`
    userApi.sendWxLoginQrcode(this.loginCode)
      .then(() => {
        this.loginCodeSendLoading = false
        this.createQr(url)
        const redirect = this.$route.query.redirect
        // @ts-ignore
        this.checkInterval = setInterval(() => {
          userApi.getWxLoginQrcodeResult(this.loginCode).then((res) => {
            if (res.login) {
              // @ts-ignore
              clearInterval(this.checkInterval)
              this.$router.push(redirect ? redirect.toString() : "/dashboard")
            }
          }).catch((_) => {
            this.showExpire = true
            // @ts-ignore
            clearInterval(this.checkInterval)
            return
          })
        }, 2000)
      }).catch((e) => {
      this.$message.warning(`二维码生成失败, ${e}`)
    })
  }

  private validatePassword = (rule: any, value: string, callback: Function) => {
    if (value.length < 4) {
      callback(new Error("密码格式错误"));
    } else {
      callback();
    }
  };

  private loginRules = {
    telephone: [{validator: this.validateUsername, trigger: "blur"}],
    password: [{validator: this.validatePassword, trigger: "blur"}],
  };

  private passwordType = "password";
  private loading = false;
  private redirect?: string;
  private otherQuery: Dictionary<string> = {};
  private showVerity = false;
  private imgs = [
    require("@/assets/slider-images/0.jpeg"),
    require("@/assets/slider-images/1.jpeg"),
    require("@/assets/slider-images/2.jpeg"),
    require("@/assets/slider-images/3.jpeg"),
  ];

  @Watch("$route", {immediate: true})
  private onRouteChange(route: Route) {
    const query = route.query as Dictionary<string>;
    if (query) {
      this.redirect = query.redirect;
      this.otherQuery = this.getOtherQuery(query);
    }
  }

  mounted() {
    //code是登录所需最终参数
    const {code} = this.$route.query
    if (code) { //登录接口
      this.handleCodeLogin(code)
    } else { //钉钉二维码
      // this.ddLoginInit()
    }
    if (this.loginForm.telephone === "") {
      (this.$refs.telephone as Input).focus();
    } else if (this.loginForm.password === "") {
      (this.$refs.password as Input).focus();
    }
  }

  private showPwd() {
    if (this.passwordType === "password") {
      this.passwordType = "";
    } else {
      this.passwordType = "password";
    }
    this.$nextTick(() => {
      (this.$refs.password as Input).focus();
    });
  }

  private handleLogin() {
    (this.$refs.loginForm as ElForm).validate(async (valid: boolean) => {
      if (!valid) {
        // @ts-ignore
        this.$refs.slideLogin.clear();
        return;
      }
      this.loading = true;
      try {
        await UserModule.Login(this.loginForm);
        await router.push({
          path: this.redirect || "/",
          query: this.otherQuery,
        });
      } catch (e) {
        // @ts-ignore
        this.$refs.slideLogin.clear();
        this.$message.error(e);
      } finally {
        this.loading = false;
        this.showVerity = false;
      }
    });
  }

  private getOtherQuery(query: Dictionary<string>) {
    return Object.keys(query).reduce((acc, cur) => {
      if (cur !== "redirect") {
        acc[cur] = query[cur];
      }
      return acc;
    }, {} as Dictionary<string>);
  }
}
