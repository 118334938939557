



























































































import { Component, Vue } from "vue-property-decorator";
import { Form as ElForm, Input } from "element-ui";
import { UserModule } from "@/store/modules/user";
import { isValidUsername } from "@/utils/validate";
import { IChangePasswordParams, userApi } from "@/api/user";
import { getQueryParamByKey } from "@/utils/index";

@Component
export default class ChangePassword extends Vue {
  params = {
    telephone: getQueryParamByKey("username"),
  } as IChangePasswordParams;
  checkPassword = "";

  initParams() {
    // const username = getQueryParamByKey("username");
    // console.log(username, "----");
    // if (username) {
    //   this.params.telephone = username;
    // }
    if (UserModule.user.telephone) {
      this.params.telephone = UserModule.user.telephone;
    }
  }

  private validateUsername = (rule: any, value: string, callback: Function) => {
    if (!isValidUsername(value)) {
      callback(new Error("登录名为11位手机号"));
    } else {
      callback();
    }
  };

  private validatePassword = (rule: any, value: string, callback: Function) => {
    if (value.length < 4) {
      callback(new Error("密码格式错误"));
    } else {
      callback();
    }
  };

  private changeRules = {
    telephone: [{ validator: this.validateUsername, trigger: "blur" }],
    oldPassword: [{ validator: this.validatePassword, trigger: "blur" }],
    password: [{ validator: this.validatePassword, trigger: "blur" }],
  };

  private passwordType = "password";
  private loading = false;

  mounted() {
    this.initParams();
    if (this.params.telephone === "") {
      (this.$refs.telephone as Input).focus();
    } else if (this.params.newPassword === "") {
      (this.$refs.password as Input).focus();
    }
  }

  private showPwd() {
    if (this.passwordType === "password") {
      this.passwordType = "";
    } else {
      this.passwordType = "password";
    }
    this.$nextTick(() => {
      (this.$refs.password as Input).focus();
    });
  }

  private handleChange() {
    if (this.params.newPassword !== this.checkPassword) {
      this.$message.warning("两次输入的密码不一致");
      return;
    }
    (this.$refs.changeRef as ElForm).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      this.loading = true;
      userApi
        .changePassword(this.params)
        .then(() => {
          this.$message.info("密码修改成功");
          this.$router.push("/");
        })
        .catch((e) => {
          this.$message.warning(e);
        })
        .finally(() => {
          this.loading = false;
        });
    });
  }
}
